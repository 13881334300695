<template>
  <div>
    <div class="bg-white w-full px-1.5" id="bar">
      <p class="sm:container text-gray-dark text-md pt-2">Prodotti</p>
    </div>
    <div class="bg-white z-10 text-gray-dark text-xs md:text-sm p-1.5 space-x-3 w-full" id="header">
      <div class="flex flex-col space-y-1.5 w-full sm:container">
        <div class="flex w-full items-center space-x-2">
          <button @click="$router.go(-1)" class="text-md text-gray-dark cursor-pointer hover:text-primary"><i
              class="fa-solid fa-arrow-left"></i></button>
          <div class="flex flex-grow bg-gray-light items-center pr-1 space-x-1 rounded">
            <input class="border-0 bg-gray-light" placeholder="Cerca SKU" type="search" v-model="search"
                   @change="select_search()">
            <i class="fa-solid rounded pr-1 fa-magnifying-glass cursor-pointer"></i>
          </div>
          <p class="text-base text-gray-dark cursor-pointer mr-2 hover:text-primary" @click="$refs.filter.open()"><i
              class="fa-solid fa-filter"></i></p>
        </div>
        <Transition
            enter-active-class="duration-300 ease-in-out"
            enter-from-class="transform-all opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform-all opacity-0"
        >
          <div
              v-if="category.name || subcategory.name || searched || filters.new || filters.promo || products_bought || selected_specs.length"
              class="flex flex-wrap space-x-2">
            <TransitionGroup
                enter-active-class="duration-300 ease-in-out"
                enter-from-class="transform-all opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform-all opacity-0"
            >
                    <span v-if="category.name" @click="remove_category()"
                          class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                        class="fa-solid fa-xmark"></i> {{
                        category.name
                      }} </span>
              <span v-if="subcategory.name" @click="remove_subcategory()"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> {{
                  subcategory.name
                }} </span>
              <span v-if="searched" @click="remove_searched()"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> {{
                  searched
                }} </span>
              <span v-if="products_bought" @click="remove_products_bought()"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Prodotti acquistati</span>
              <span v-if="filters.promo" @click="filters.promo = false"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Promo </span>
              <span v-if="filters.new" @click="filters.new = false"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Novità </span>

              <template v-for="(vals, spec_id) in selected_specs">
                <template v-if="vals['max']">
                  <span @click="changeSpecs(spec_id, [])"
                        class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                      class="fa-solid fa-xmark"></i> {{ getSpecName(spec_id) }}: {{ vals['min'] }} - {{vals['max']}} </span>
                </template>
                <template v-else>
                  <span v-for="val in vals" @click="removeSpec(spec_id, val)"
                        class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer">
                    <i class="fa-solid fa-xmark"></i> {{ getSpecName(spec_id) }}: {{ val }} </span>
                </template>

              </template>


            </TransitionGroup>
          </div>
        </Transition>
      </div>
    </div>

    <div class="container p-2">
      <Transition
          enter-active-class="duration-300 ease-in-out"
          enter-from-class="transform-all opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform-all opacity-0"
      >
        <div class="w-full" v-if="categories && categories.length && filters.promo===false && filters.new===false">
          <TransitionGroup
              enter-active-class="duration-700 ease-in-out"
              enter-from-class="transform-all opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="transform-all opacity-0"
          >
<!--            <div class="flex flex-wrap justify-center mb-3" v-if="order_id">
              <button
                  class="flex flex-col h-full bg-gradient-to-br from-primary-light to-primary rounded border-gray-light hover:border-primary-light hover:shadow-light transition-all"
                  @click="select_bought()">
                <span class="px-2 py-2 text-center text-white">Tutti i prodotti acquistati</span>
              </button>
            </div>-->
          </TransitionGroup>

          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
            <TransitionGroup
                enter-active-class="duration-700 ease-in-out"
                enter-from-class="transform-all opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform-all opacity-0"
            >
              <button v-for="category in categories" @click="select_cat(category.id, category.name)"
                      class="flex flex-col h-full bg-white border rounded border-gray-light hover:border-primary-light hover:shadow-light transition-all"
                      :key="category.id">
                <img :src="category.url" :alt="category.name" class="p-2">
                <span class="px-2 py-2 text-center">{{ category.name }}</span>
              </button>
            </TransitionGroup>
          </div>
        </div>
      </Transition>
      <Transition v-if="!filters.new && !filters.promo"
                  enter-active-class="duration-300 ease-in-out"
                  enter-from-class="transform-all opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="transform-all opacity-0"
      >
        <div class="w-full">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4"
               :class="{'mb-5': subcategories && subcategories.length}">
            <TransitionGroup
                enter-active-class="duration-700 ease-in-out"
                enter-from-class="transform-all opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform-all opacity-0"
            >
              <button v-for="subcategory in subcategories" @click="select_sub(subcategory.id, subcategory.name)"
                      class="flex flex-col h-full rounded bg-gradient-to-br from-primary-light to-primary rounded border-gray-light hover:border-primary-light hover:shadow-light transition-all"
                      :key="subcategory.id">
                <span class="px-2 py-2 text-center text-white">{{ subcategory.name }}</span>
              </button>
            </TransitionGroup>
          </div>
        </div>
      </Transition>

      <p class="text-md text-gray-dark mb-2"
         v-if="subcategories && subcategories.length && products && products.length && !filters.new && !filters.promo && !searched_product">
        Senza
        sottocategoria</p>
      <div class="relative grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4" id="products"
           ref="products">
        <div
            class="lg:absolute fixed bg-white opacity top-0 bottom-0 w-full flex justify-center z-10 transition-all min-h-full"
            :class="{'hidden':true}">
                    <span class="flex items-center text-title lg:h-full max-h-screen"
                          :class="{'h-full':!(this.products && this.products.length), 'h-screen': this.products && this.products.length}">
                        <i class="fas fa-spinner animate-spin"></i>
                    </span>
        </div>
        <TransitionGroup
            enter-active-class="duration-700 ease-in-out"
            enter-from-class="transform-all opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform-all opacity-0"
        >
          <div v-if="products && products.length" v-for="product in products"
               class="w-full" :key="product.id">
            <product-card :product="product" :order_id="order_id" :key="product.id"></product-card>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <slideover ref="filter" title="Filtri">
      <template v-slot:content>
        <div class="flex items-center">
          <input type="checkbox" @change="filters['new'] = !filters['new']"
                 :checked="filters['new']" id="new" class="h-[1.5rem] w-[1.5rem]">
          <label for="new" class="mb-0 ml-1 text-gray-dark text-base">Novità</label>
        </div>
        <div class="flex items-center mt-1.5">
          <input type="checkbox" @change="filters['promo'] = !filters['promo']"
                 :checked="filters['promo']" id="promo" class="h-[1.5rem] w-[1.5rem]">
          <label for="promo" class="mb-0 ml-1 text-gray-dark text-base">Prodotti in promozione</label>
        </div>
        <div v-if="Object.keys(specs).length" class="py-2">
          <p class="text-lg text-gray-dark">Specifiche</p>
          <component v-for="spec in specs" :key="spec.id"
                     :is="spec.component || 'simple'" :id="spec.id"
                     :values="spec.values" :name="spec.name" :description="spec.description"
                     :icon="spec.icon"
                     :changeSpecs="changeSpecs" :getSpec="getSpec" :loading="loading"></component>
        </div>
      </template>
    </slideover>
    <navbar v-if="!order_id"></navbar>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";
import ProductCard from "./product-card.vue";
import Slideover from "../../components/slideover.vue";
import range from "./components/range.vue"
import simple from "./components/simple.vue"

export default {
  name: "product-index",
  components: {Slideover, ProductCard, navbar, range, simple},

  props: {
    category_name: null,
    subcategory_name: null,
    searched_product: null,
    products_bought: false,
    order_id: null,
    from: null,
  },
  data: () => ({
    categories: [],
    products: [],
    subcategories: [],
    currentPage: 1,
    lastPage: 1,
    category: {
      id: null,
      name: null
    },
    subcategory: {
      id: null,
      name: null
    },
    search: null,
    searched: null,
    loading: false,
    filters: {
      promo: false,
      new: false
    },
    construct: {
      name: 'products.index',
      params: {},
      query: {}
    },
    specs: {},
    selected_specs: {}
  }),
  methods: {
    changeSpecs: function (spec_id, value) {
      console.log(value);
      if (Object.keys(value).length)
        this.selected_specs[spec_id] = value;
      else delete this.selected_specs[spec_id];
      this.currentPage = 1;
      this.load_products(true);
    },
    getSpec(spec_id) {
      return (this.selected_specs[spec_id] || []).slice()
    },
    getSpecName(spec_id){
      return this.specs.filter(v => v.id == spec_id)[0].name;
    },
    removeSpec(spec_id, val){
      let arr = this.getSpec(spec_id);
      let index = arr.indexOf(val);
      arr.splice(index, 1);
      console.log(val);
      console.log(spec_id);
      console.log(index);
      console.log(arr);
      this.changeSpecs(spec_id, arr);
    },
    async load() {
      if (this.loading) return;
      let l = this.$loading.show();
      this.loading = true;
      this.category = {
        id: null,
        name: null
      };
      this.subcategory = {
        id: null,
        name: null
      };
      this.selected_specs = {};
      this.specs = {};
      this.lastPage = 1;
      this.currentPage = 1;
      this.products = [];
      this.categories = [];
      this.subcategories = [];
      if (this.$props.searched_product)
        this.searched = this.unslug(this.$props.searched_product);

      if (this.$props.category_name) {
        let cat = (await axios.get('/prodotti/categories')).data.filter(v => this.slug(v.name) === this.$props.category_name).shift() || null;
        if (!cat) await this.$router.replace({name: 'products.index'});
        this.category.id = cat.id;
        this.category.name = cat.name;
        if (this.$props.subcategory_name) {
          let sub = (await axios.get('/prodotti/subcategories', {params: {category_id: this.category.id}})).data.filter(v => this.slug(v.name) === this.$props.subcategory_name).shift() || null;
          if (!sub) await this.$router.replace({name: 'products.index'});
          this.subcategory.id = sub.id;
          this.subcategory.name = sub.name;
        } else
          this.subcategories = (await axios.get('/prodotti/subcategories', {params: {category_id: this.category.id}})).data || [];
      }
      if (!(this.category_name || this.category_name || this.subcategory_name || this.products_bought || this.searched_product))
        this.categories = (await axios.get('/prodotti/categories')).data;
      else
        await this.load_products(false);
      l.hide();
      this.loading = false;
    },
    async load_products(loading = true) {
      let l = null;
      if (loading) l = this.$loading.show();
      this.loading = true;
      let data = (await axios.get('/prodotti/products', {
        params: {
          category_id: this.category.id,
          subcategory_id: this.subcategory.id,
          search: this.searched,
          page: this.currentPage,
          order_id: this.$props.order_id,
          promo: this.filters.promo,
          new: this.filters.new,
          products_bought: this.products_bought,
          specs: this.selected_specs
        }
      })).data;
      if (data.products && data.current_page !== 1)
        data.products.forEach(v => this.products.push(v));
      else this.products = data.products;
      this.lastPage = data.last_page;
      this.currentPage = data.current_page;
      this.specs = data.specifications;
      this.loading = false;
      if (loading) l.hide();
    },
    select_sub(id, name) {
      this.subcategory.id = id;
      this.subcategory.name = name;
      this.construct.params.subcategory_name = this.slug(this.subcategory.name);
      this.refresh();
    },
    select_cat(id, name) {
      this.category.id = id;
      this.category.name = name;
      this.construct.params.category_name = this.slug(this.category.name);
      this.refresh();
    },
    select_search() {
      this.searched = this.search;
      this.construct.query.searched_product = this.searched;
      this.refresh();
    },
    select_bought() {
      this.construct.query.products_bought = true;
      this.refresh();
    },
    remove_category() {
      delete this.construct.params.category_name;
      delete this.construct.params.subcategory_name;
      delete this.construct.query.searched_product;
      this.refresh();
    },
    remove_subcategory() {
      delete this.construct.params.subcategory_name;
      delete this.construct.query.searched_product;
      this.refresh()
    },
    remove_searched() {
      delete this.construct.query.searched_product;
      this.searched = null;
      this.search = null;
      this.refresh()
    },
    remove_products_bought() {
      delete this.construct.query.products_bought;
      this.refresh()
    },
    change_promo() {
      this.currentPage = 1;
      this.lastPage = 1;
      this.load_products();
    },
    change_new() {
      this.currentPage = 1;
      this.lastPage = 1;
      this.load_products();
    },
    refresh() {
      window.scrollTo(0, 0);
      this.$router.replace(this.construct);
    },
    loadScrolling() {
      let header = document.getElementById('header');
      let sticky = header.offsetTop;
      let bar = document.getElementById('bar');
      window.onscroll = () => {
        if (window.pageYOffset > sticky) {
          header.classList.add('sticky');
          header.classList.add('top-0');
          bar.classList.remove('top-0');
        } else {
          bar.classList.add('top-0');
          header.classList.remove('sticky');
          header.classList.remove('top-0');
        }
        let productsList = this.$refs.products.getBoundingClientRect();
        let bottomOfWindow = productsList.bottom < window.innerHeight;
        let page = this.currentPage + 1;
        if (bottomOfWindow && (page <= this.lastPage) && !this.loading) {
          this.currentPage++;
          this.load_products();
        }
      }
    },
    slug(str) {
      return str.replaceAll(' ', '-');
    },
    unslug(str) {
      return str.replaceAll('-', ' ');
    },
    go_order() {
      if (!this.from) this.$router.replace({name: 'orders.index'});
      else this.$router.replace({name: 'customers.orders', params: {id: this.from}});
      this.$router.push({name: 'orders.edit', params: {id: this.order_id}});
    },
    initiate_construct() {
      if (this.order_id) this.construct.query.order_id = this.order_id;
      if (this.$props.products_bought) this.construct.params.products_bought = "true";
      if (this.$props.category_name) this.construct.params.category_name = this.slug(this.$props.category_name);
      if (this.$props.subcategory_name) this.construct.params.subcategory_name = this.slug(this.$props.subcategory_name);
      if (this.$props.searched_product) this.construct.params.searched_product = this.searched_product;
    }
  },
  mounted() {
    this.initiate_construct();
    this.load();
    this.loadScrolling();
    this.$watch('category_name', this.load);
    this.$watch('subcategory_name', this.load);
    this.$watch('searched_product', this.load);
    this.$watch('filters.new', this.change_new);
    this.$watch('filters.promo', this.change_promo);
    this.$watch('products_bought', this.load);
  },
  beforeDestroy() {
    window.onscroll = () => null;
  },
  beforeUnmount() {
    window.onscroll = () => null;
  },
  watch: {
    specs: function(){
      console.log(this.specs);
    }
  }
}
</script>

<style scoped>

</style>
