<template>
    <div class="flex flex-col py-2 text-base">
        <span class="text-primary font-bold flex justify-between cursor-pointer" @click="show = !show">
            <span>
                <span v-if="description" :data-tippy-content="description">{{ name }}</span><span v-else>{{ name }}</span>
            </span>
            <button type="button" class="ml-1 focus:outline-none focus:text-primary text-sm" :class="{hidden: show}">
                <i class="fas fa-plus"></i>
            </button>
            <button type="button" class="ml-1 focus:outline-none focus:text-primary text-sm" :class="{hidden: !show}">
                <i class="fas fa-minus"></i>
            </button>
        </span>
        <div class="transition ease-in-out overflow-hidden specs-list" :class="{'max-h-0': !show}">
            <div v-for="content in values" :key="content.value" class="flex items-center text-sm my-1 px-1">
                <button @click="filter(content)">{{ content.name }}
                    <span v-if="content.count">[{{ content.count }}]</span>
                </button>
            </div>
            <div class="flex flex-col space-y-1">
                <div class="flex">
                    <input type="text" placeholder="Min" v-model="searched.min" class="h-3 mx-1 border-solid border-gray-light bg-transparent">
                    <input type="text" placeholder="Max" v-model="searched.max" class="h-3 mx-1 border-solid border-gray-light bg-transparent">
                </div>
                <button class="btn btn-primary-outline h-3" @click="submit">Vai</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "range",
    props: ['id', 'values', 'name', 'description', 'icon', 'changeSpecs', 'loading'],
    data: () => ({
        show: false,
        searched: {
            min: null,
            max: null
        },
    }),
    methods: {
        submit: function () {
            if (this.searched.max < 0 || isNaN(this.searched.max)) this.searched.max = null;
            if (this.searched.min < 0 || isNaN(this.searched.min)) this.searched.min = null;
            if ((this.searched.min != null && this.searched.max != null) && (this.searched.max < this.searched.min)) {
                let tmp = this.searched.min;
                this.searched.min = this.searched.max;
                this.searched.max = tmp;
            }
            if(!this.searched.max && !this.searched.min) this.changeSpecs(this.id, {})
            else this.changeSpecs(this.id, this.searched);
        },
        filter(a) {
            this.searched.min = a.min;
            this.searched.max = a.max;
            this.submit();
        }
    },
    mounted() {
    }
}
</script>
