<template>
  <div class="flex flex-col py-2 text-base">
                <span class="text-primary font-bold flex justify-between cursor-pointer"
                      @click="show = !show">
                    <span><span v-if="description" :data-tippy-content="description">{{ name }}</span><span
                        v-else>{{ name }}</span></span>
                    <button type="button" class="ml-1 focus:outline-none focus:text-primary text-sm"
                            :class="{hidden: show}">
                        <i class="fas fa-plus"></i>
                    </button>
                    <button type="button" class="ml-1 focus:outline-none focus:text-primary text-sm"
                            :class="{hidden: !show}">
                        <i class="fas fa-minus"></i>
                    </button>
                </span>
    <div class="transition ease-in-out overflow-hidden specs-list" :class="{'max-h-0': !show}">
      <div class="flex justify-center mt-1 mb-2">
        <input type="text" v-model="searched" placeholder="Cerca..."
               class="h-3 mx-1 border-solid border-gray-light bg-transparent">
      </div>
      <div v-for="content in sorted" :key="content.value" class="flex items-center text-sm my-1 px-1">
        <input type="checkbox"
               class="mr-1 auto-submit" :checked="is_selected(content.value)" @change="check(content.value)"
               :class="{disabled: loading}">
        <label :for="'s'+id+'-'+content.value">{{ content.name }}
          <span v-if="content.count">[{{ content.count }}]</span>
        </label>
      </div>
      <div v-if="!searched && values.length > sliced" @click="show_all = !show_all">
        <span v-if="!show_all" class="text-primary hover:cursor-pointer">Mostra Tutti</span>
        <span v-else class="text-primary hover:cursor-pointer">Nascondi</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "simple",
  props: ['id', 'values', 'name', 'description', 'icon', 'changeSpecs', 'loading', 'getSpec'],
  data: () => ({
    show: false,
    searched: null,
    filtered: null,
    show_all: false
  }),
  methods: {
    is_selected: function (val) {
      return this.getSpec(this.id).indexOf(val) > -1
    },
    check: function (val) {
      let vals = this.getSpec(this.id);
      let index = vals.indexOf(val);
      if (this.is_selected(val)) {
        vals.splice(index, 1)
      } else vals.push(val);
      this.changeSpecs(this.id, vals)
    },
  },
  mounted() {
    this.filtered = JSON.parse(JSON.stringify(this.$props.values));
  },
  watch: {
    searched: function () {
      if (this.searched !== null) {
        this.filtered = this.$props.values.filter(v => v.name && v.name.toUpperCase().includes(this.searched.toUpperCase()));
      } else this.filtered = JSON.parse(JSON.stringify(this.$props.values));
    },
    values: function () {
      this.filtered = JSON.parse(JSON.stringify(this.$props.values));
    }
  },
  computed: {
    sorted: function () {
      if (this.filtered) {
        let a = this.filtered.sort((v1, v2) => {
          v1.name.toUpperCase().localeCompare(v2.name.toUpperCase());
        }).sort((v1, v2) => this.is_selected(v2.value) - this.is_selected(v1.value));
        if (this.searched) return a;
        return this.show_all ? a : a.slice(0, this.sliced);
      }
      return JSON.parse(JSON.stringify(this.$props.values));
    },
    sliced: function () {
      return Math.max((Math.abs(this.$props.values.length - 3) < 5 ? this.$props.values.length : 5),
          this.$props.values.filter(v => this.is_selected(v.value)).length);
    }
  }
}
</script>

<style scoped>

</style>
